<template>
  <div class="p404">
    <h1>
      404
      <br><small>Hier gibt es nichts zu sehen.</small>
    </h1>
  </div>
</template>


<script>
export default {
  setup() {

    return {
    }
  },
}
</script>


<style lang="scss" scoped>
@import '~@/assets/scss/colors';

.p404 {
  position: absolute;
  top: 6%;
  left: 15%;
  width: 85%;
  height: 94%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #232429;

  h1 {
    text-align: center;
    font-size: 8rem;
    line-height: 1em;

    small {
      display: block;
      font-size: 2rem;
      line-height: 1.5em;
    }
  }
}
</style>
